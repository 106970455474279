import type { PriceRange } from "~/types";

// プラン例　概算数
export const PLAN_COUNT = 1000;

export const carCount = [
  { id: 1, name: "1台" },
  { id: 2, name: "2台" },
  { id: 3, name: "3台以上" },
];

export const planType = [
  { id: 1, name: "新築外構" },
  { id: 2, name: "リフォーム外構" },
];

export const tsuboRange = [
  { id: 1, name: "10m2まで" },
  { id: 2, name: "10m2から20m2まで" },
  { id: 3, name: "20m2から30m2まで" },
  { id: 4, name: "30m2から40m2まで" },
  { id: 5, name: "40m2から50m2まで" },
  { id: 6, name: "50m2から60m2まで" },
  { id: 7, name: "60m2から70m2まで" },
  { id: 8, name: "70m2から80m2まで" },
  { id: 9, name: "80m2から90m2まで" },
  { id: 10, name: "90m2から100m2まで" },
  { id: 11, name: "100m2以上" },
];

export const orderType = [
  { id: "date", name: "登録日" },
  { id: "price", name: "価格" },
];

export const orderList = [
  { name: "新しい順", order: "date", asc: 0 },
  { name: "古い順", order: "date", asc: 1 },
  { name: "価格の安い順", order: "price", asc: 1 },
  { name: "価格の高い順", order: "price", asc: 0 },
];

export const houiList = [
  { name: "北" },
  { name: "北東" },
  { name: "東" },
  { name: "南東" },
  { name: "南" },
  { name: "南西" },
  { name: "西" },
  { name: "北西" },
];

export const priceRange: PriceRange[] = [
  {
    id: 1,
    from: "",
    to: "30",
    name: "~30万円まで",
    description: `門柱だけのプチリフォームをお考えの方、カーポートやウッドデッキを後付けでお考えの方など、ご予算に沿ったプランをご予算や敷地面積別に絞り込んでご覧いただけます。`,
    text: `ご予算が30万円以内の外構工事は、カーポート1台用やポスト、門柱だけなどの門まわり、車庫前ゲートなど、部分に絞ったリフォーム工事が可能です。大規模な工事ではなく「ちょっとだけ直したい・・」といった方へオススメです。商品単体での工事OK！`,
  },
  {
    id: 2,
    from: "30",
    to: "60",
    name: "30~60万円まで",
    description: `駐車スペースのやり替え、門まわりや玄関アプローチなどのプラン事例がたくさん。ご予算や車の台数、敷地面積ごとに絞り込んでイッキ見できちゃいます！`,
    text: `ご予算が30万～60万円の外構工事は、カーポート1台用+土間コンクリート舗装、機能門柱+アプローチ部分などのリフォーム工事が可能です。敷地面積やお車の台数などによって工事内容は大きく変わってきます。今はひとまず必要なものだけにして、暮らしていく中でまた必要になったものを追加工事する、といったような段階的に外構計画を立てられる方へオススメです。`,
  },
  {
    id: 3,
    from: "60",
    to: "100",
    name: "60~100万円まで",
    description: `
        シンプル新築外構、お庭のリフォームなどのプランをご覧いただけます。
        `,
    text: `
        ご予算が100万円以内の外構工事は、アプローチ、車庫前ゲートなどを含めたシンプル新築外構や、お庭に人工芝+テラス屋根やデッキなどの工事が可能です。コンパクトな敷地なら、商品をグレードアップして納得のいくデザインにしていただけます。敷地面積やお車の台数などによっても工事内容は大きく変わってきますので、ご予算に合わせてプランをご検討下さい。
        `,
  },
  {
    id: 4,
    from: "100",
    to: "150",
    name: "100~150万円まで",
    description: `新築外構、お庭のリフォームなどのプランをご覧いただけます。`,
    text: `ご予算が150万円以内の外構工事は、お家周りまるまるの外構工事や、ご予算を掛けたお庭のリフォームなどが可能です。お車2台以上の駐車スペース、お隣からの視線を防ぐ目隠しフェンス、門柱セットを含むアプローチなど、一通りのお家まわりを整えて頂くことが出来ます。`,
  },
  {
    id: 5,
    from: "150",
    to: "200",
    name: "150~200万円まで",
    description: `おしゃれな新築外構、お庭のリフォームなどのプランをご覧いただけます。`,
    text: `ご予算が200万円以内の外構工事は、デザイン性のある門まわりや、2台用カーポート、土間コンクリート、お子様やペットも喜ぶお庭周りのプランなどがございます。タイル門柱、ナチュラルな印象の乱形石アプローチ等、様々な事例をぜひご覧ください。`,
  },
  {
    id: 6,
    from: "200",
    to: "250",
    name: "200~250万円まで",
    description: `こだわりの新築外構、お庭のリフォームなどのプランをご覧いただけます。人気のカーポートSCを使ったプランも！`,
    text: `ご予算が200万円以上の外構工事、こだわりの玄関まわりや大規模なお庭リフォームプランや、和モダンの要素を取り入れたオシャレで高級感のあるプランを掲載しています。人気のLIXILカーポートSCや、エクステリア商品をグレードアップして、理想の外構を実現してください。`,
  },
  {
    id: 7,
    from: "250",
    to: "",
    name: "250万円~",
    description: `ハイグレードな外構工事一式、空間拡張などの大規模なリフォームプランをご覧いただけます。`,
    text: `ご予算が250万円以上の外構工事は、空間拡張などの大規模なお庭やカースペースリフォームや、ハイグレードな外構一式のプランが可能です。お車3台用のカーポートや、エクステリア商品をより高級感のある木製調にアップグレードしたり、自由な外構プランをご覧ください。`,
  },
];
